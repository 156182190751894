<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <TransitionRoot
      as="template"
      :show="open"
    >
      <Dialog
        as="div"
        static
        class="fixed inset-0 overflow-hidden"
        @close="open = false"
        :open="open"
      >
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay class="absolute inset-0" />
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="w-screen max-w-md">
                <div class="
                  h-full
                  flex flex-col
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                ">
                  <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Send Quote Email
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          class="
                          bg-light-blue-900
                          rounded-md
                          text-blue-200
                          hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white
                        "
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon
                            class="h-6 w-6"
                            aria-hidden="true"
                          />
                          </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">
                        Fill in the information below to send a new quote, which will be emailed to the client to start the application.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex-1 py-6 px-4 sm:px-6">
                    <div class="space-y-6 sm:space-y-5">
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-gray-200
                      ">
                        <label
                          for="client_name"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Client Name
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              name="client_name"
                              id="client_name"
                              autocomplete="given-name"
                              class="
                            max-w-lg
                            block
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:max-w-xs sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="client_email"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Client Email
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="email"
                              name="client_email"
                              id="client_email"
                              autocomplete="email"
                              class="
                            max-w-lg
                            block
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:max-w-xs sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="subject"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Subject
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="subject"
                              name="subject"
                              type="text"
                              class="
                            block
                            max-w-lg
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="text"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Text
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea
                              id="text"
                              name="text"
                              rows="3"
                              class="
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                            ></textarea>
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="type_of_sale"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Stamp duty rate type
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              id="buyer"
                              name="buyer"
                              class="
                                form-select appearance-none
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                            >
                              <option selected>Select Type</option>
                              <option value="normal">Normal</option>
                              <option value="additional">Additional</option>

                              </select>
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="type_of_sale"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                        Transaction
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              id="type_of_sale"
                              name="type_of_sale"
                              class="
                                form-select appearance-none
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding bg-no-repeat
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                              @change="getPreview"
                            >
                              <option selected>Select Type</option>
                              <option
                                v-for="type in types"
                                :key="type.name"
                                :value="type.name"
                              >{{type.name}}</option>
                                </select>
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="property_price"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Property Price
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="property_price"
                              name="property_price"
                              type="text"
                              class="
                            block
                            max-w-lg
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                              @change="getPreview"
                              @input="handleOnChange"
                            />
                          </div>
                      </div>
                      <div class="
                        sm:grid
                        sm:grid-cols-3
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <label
                          for="preview_price"
                          class="
                          block
                          text-sm
                          font-medium
                          text-gray-700
                          sm:mt-px sm:pt-2
                        "
                        >
                          Preview Price
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="preview_price"
                              name="preview_price"
                              type="text"
                              class="
                            block
                            max-w-lg
                            w-full
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                              readonly
                              :value="SDLTfee"
                            />
                          </div>
                      </div>

                      <div class="
                        sm:grid sm:grid-cols-1
                        lg:justify-self-end
                        sm:gap-4
                        sm:items-start
                        sm:border-t
                        sm:border-gray-200
                        sm:pt-5
                      ">
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <span class="relative z-0 inline-flex shadow-sm rounded-md">
                            <span class="
                              relative
                              inline-flex
                              items-center
                              px-2
                              py-2
                              rounded-l-md
                              border border-gray-300
                              bg-white
                            ">
                              <label
                                for="select-all"
                                class="sr-only"
                              >Select all</label>
                                <input
                                  id="select-all"
                                  type="checkbox"
                                  name="select-all"
                                  class="
                                h-4
                                w-4
                                text-blue-600
                                focus:ring-blue-500
                                border-gray-300
                                rounded
                              "
                                />
                            </span>
                            <label
                              for="message-type"
                              class="sr-only"
                            >Select message type</label>
                              <select
                                id="message-type"
                                name="message-type"
                                class="
                              -ml-px
                              block
                              w-full
                              pl-3
                              pr-9
                              py-2
                              rounded-l-none rounded-r-md
                              border border-gray-300
                              bg-white
                              text-sm
                              font-medium
                              text-gray-700
                              hover:bg-gray-50
                              focus:z-10
                              focus:outline-none
                              focus:ring-1
                              focus:ring-blue-500
                              focus:border-blue-500
                            "
                              >
                                <option selected>Discount fees</option>
                                <option>Fee type 1</option>
                                <option>Fee type 2</option>
                                </select>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class=" sm:grid sm:grid-cols-1 text-red-700 help-desk-error  px-1 py-1"></div>
                    <div class=" sm:grid sm:grid-cols-1 text-green-700 help-desk-success  px-1 py-1"></div>
                  </div>
                  <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-500
                    "
                      @click="open = false"
                    >
                      Cancel
                      </button>
                      <button
                        type="submit"
                        class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-500
                    "
                        @click="sendEmail"
                      >
                        Send
                        </button>
                  </div>
                </div>
              </div>
              </TransitionChild>
          </div>
        </div>
        </Dialog>
        </TransitionRoot>
        <div class="flex flex-col">
          <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <nav
              class="
          flex
          mb-8
          justify-between
          sm:px-6
          lg:px-8
          flex-wrap
          gap-y-4
          lg:h-10
          md:h-10
        "
              aria-label="Breadcrumb"
            >
              <ol class="flex items-center space-x-4">
                <li>
                  <div>
                    <a
                      href="#"
                      class="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        class="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Home</span>
                      </a>
                  </div>
                </li>
                <li
                  v-for="page in pages"
                  :key="page.name"
                >
                  <div class="flex items-center">
                    <ChevronRightIcon
                      class="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <a
                      :href="page.href"
                      class="
                  ml-4
                  text-sm
                  font-medium
                  text-gray-500
                  hover:text-gray-700
                "
                      :aria-current="page.current ? 'page' : undefined"
                    >{{ page.name }}</a>
                  </div>
                  </li>
              </ol>
              <div class="flex flex-wrap gap-4 items-center">
                <div class="relative rounded-md shadow-sm">
                  <div class="
                absolute
                inset-y-0
                left-0
                pl-3
                flex
                items-center
                pointer-events-none
              ">
                    <SearchIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="email"
                    class="
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                pl-10
                sm:text-sm
                border-gray-300
                rounded-md
              "
                    placeholder="Search for cases"
                  />
                </div>
                <button
                  @click="open = true"
                  type="button"
                  class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-white
              bg-light-blue-600
              hover:bg-light-blue-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-light-blue-500
            "
                >
                  <FolderAddIcon
                    class="mr-3 h-5 w-5"
                    aria-hidden="true"
                  /> Send quote email
                  </button>
              </div>
              </nav>
              <div class="
          py-2
          align-middle
          inline-block
          min-w-full
          sm:px-6
          lg:px-8
          max-w-full
        ">
                <div v-if="cases.items.length > 0"
                  class="
            shadow
            border-b border-gray-200
            sm:rounded-tl-lg sm:rounded-tr-lg
            overflow-auto
          "
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                        >
                          NAME & START DATE
                          </th>
                          <th
                            scope="col"
                            class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                          >
                            CLIENT
                            </th>
                            <th
                              scope="col"
                              class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                            >
                              STAFF
                              </th>
                              <th
                                scope="col"
                                class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                              >
                                STAGE
                                </th>
                                <th
                                  scope="col"
                                  class="relative px-6 py-3"
                                >
                                  <span class="sr-only">Edit</span>
                                  </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr
                        :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                        v-for="(item, i) in cases.items"
                        :key="item.id"
                      >
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div>
                              <div
                                :title="item.info.address.street_address"
                                class="
                          text-sm
                          font-medium
                          text-gray-900
                          lg:truncate
                          md:truncate
                          max-w-xs
                        "
                              >
                                {{ item.info.address.street_address }}
                            </div>
                            <div class="text-sm text-gray-500">
                              {{ DateTime.fromISO(item.created_at).toFormat( "MMMM dd, yyyy" ) }}
                            </div>
                          </div>
                </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <router-link
                    :to="{ name: 'client', params: { id: item.user.id } }"
                    class="flex items-center"
                  >
                    <div class="flex-shrink-0 h-10 w-10">
                      <Avatar
                        :user="item.user"
                        :className="['h-8', 'w-8', 'rounded-full']"
                      />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ item.user.first_name }} {{ item.user.last_name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ item.user.email }}
                      </div>
                    </div>
                    </router-link>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center w-40">
                    <div class="flex -space-x-2 overflow-hidden">
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                        alt=""
                      />
                      <img
                        class="
                          inline-block
                          h-10
                          w-10
                          rounded-full
                          ring-2 ring-white
                        "
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    :class="`text-${item.info.stage.color}-500 bg-${item.info.stage.color}-100`"
                    class="
                      inline-flex
                      items-center
                      px-2.5
                      py-0.5
                      rounded-md
                      text-sm
                      font-medium
                    "
                  >
                    <svg
                      :class="`text-${item.info.stage.color}-200`"
                      class="-ml-0.5 mr-1.5 h-2 w-2"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle
                        cx="4"
                        cy="4"
                        r="3"
                      />
                      </svg>
                      {{ item.info.stage.name }}
                      </span>
                </td>
                <td class="
                    px-6
                    py-4
                    whitespace-nowrap
                    text-right text-sm
                    font-medium
                  ">
                  <router-link
                    :to="{ name: 'staff-case', params: { id: item.id } }"
                    class="text-blue-600 hover:text-blue-900"
                  >View-></router-link>
                </td>
                </tr>
                </tbody>
                </table>
              </div>
              <Pagination
                @page-changed="handlePageChanged"
                :total="cases.count"
                :current_page="current_page"
              />
          </div>
        </div>
  </div>
  </div>
</template>
<script>
import {
  ChevronRightIcon,
  HomeIcon,
  SearchIcon,
  FolderAddIcon
} from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import axios from "axios";
const pages = [{ name: "Cases", href: "#", current: true }];

import { ref, computed, onMounted } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import Pagination from "@/components/Pagination";
import Avatar from "@/components/Avatar";
import { getAppTypes } from "../../../services/case";

export default {
  components: {
    Avatar,
    ChevronRightIcon,
    HomeIcon,
    SearchIcon,
    FolderAddIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Pagination
  },
  setup() {
    const store = useStore();
    const types = ref("");
    let SDLTfee = ref(0);

    onMounted(async () => {
      try {
        const { data } = await getAppTypes();
        types.value = data;
      } catch (e) {
        console.log(e);
      }
    });

    let user = JSON.parse(localStorage.getItem("auth-user"));
    store.dispatch("cases/getCases", { id: user.id });

    const open = ref(false);
    const current_page = ref(1);
    const handlePageChanged = async page => {
      let user = JSON.parse(localStorage.getItem("auth-user"));
      page.id = user.id;
      await store.dispatch("cases/getCases", page);
      current_page.value = page;
    };
    const handleOnChange = e => {
      let value = e.target.value.replaceAll(",", "");
      e.target.value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const getPreview = async () => {
      let Tax125 = 0,
        Tax250 = 0,
        Tax925 = 0,
        Tax = 0,
        Tax0 = 0;

      let property = document.getElementById("type_of_sale").value;
      let property_price = document
        .getElementById("property_price")
        .value.replaceAll(",", "");
      let buyer = document.getElementById("buyer").value;
      console.log("property", property);
      if (property_price == "") {
        return;
      }

      if (property == "Purchase" || property == "Sale & Purchase") {
        console.log("here", property_price);
        let value = property_price.replaceAll(",", "");
        if (value > 0) {
          if (buyer === "additional") {
            let valAdd = value >= 125000 ? 125000 : value;
            let TaxableVal0 = valAdd;
            Tax0 = TaxableVal0 * 0.03;
          }
        }
        if (value >= 125000) {
          let val = value >= 250000 ? 250000 : value;
          let TaxableVal125 = val - 125000;
          Tax125 = TaxableVal125 * 0.02;
        }
        if (value >= 250000) {
          let val = value >= 925000 ? 925000 : value;
          let TaxableVal250 = val - 250000;
          Tax250 = TaxableVal250 * 0.05;
        }
        if (value >= 925000) {
          let val = value >= 1500000 ? 1500000 : value;
          let TaxableVal925 = val - 925000;
          Tax925 = TaxableVal925 * 0.1;
        }
        if (value >= 1500000) {
          let val = value;
          let TaxableVal = val - 1500000;
          Tax = TaxableVal * 0.12;
        }
        let total = Tax0 + Tax125 + Tax250 + Tax925 + Tax;
        SDLTfee = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        console.log("SDLTfee", SDLTfee);
        document.getElementById("preview_price").value = SDLTfee;
      } else {
        document.getElementById("preview_price").value = 0;
      }
    };
    //send email
    const sendEmail = async () => {
      let client_name = document.getElementById("client_name").value;
      let client_email = document.getElementById("client_email").value;
      let subject = document.getElementById("subject").value;
      let type_of_sale = document.getElementById("type_of_sale").value;
      let property_price = document.getElementById("property_price").value;
      let selectAll = document.getElementById("select-all").checked;
      let messageType = document.getElementById("message-type").value;
      let text = document.getElementById("text").value;
      let preview_price = document.getElementById("preview_price").value;

      document.getElementsByClassName("help-desk-error")[0].innerHTML = "";
      document.getElementsByClassName("help-desk-success")[0].innerHTML = "";

      let discountFee;
      if (selectAll) {
        discountFee = "Fee type 1,Fee type 2";
      } else {
        discountFee = messageType;
      }
      if (
        client_name == "" ||
        client_email == "" ||
        subject == "" ||
        type_of_sale == "" ||
        property_price == "" ||
        text == ""
      ) {
        document.getElementsByClassName("help-desk-error")[0].innerHTML =
          "Please fill all inputs";
        return;
      }

      // console.log(client_name);
      // console.log(client_email);
      // console.log(subject);
      // console.log(type_of_sale);
      // console.log(property_price);
      // console.log(selectAll);
      // console.log(messageType);
      // console.log(preview_price);

      let savedData = {
        client_name: client_name,
        client_email: client_email,
        subject: subject,
        text: text,
        type_of_sale: type_of_sale,
        property_price: property_price,
        message_type: messageType,
        discount_fee: discountFee,
        preview_price: preview_price
      };

      let data = {
        to: client_email,
        subject: subject,
        name: client_name,
        type_of_sale: type_of_sale,
        property_price: property_price,
        messageType: messageType,
        preview_price: preview_price,
        discount_fee: discountFee,
        text: text
      };
      // console.log("data", data)
      console.log("data hereeee", savedData);
      let a = await store.dispatch("cases/createEmailsLogs", savedData);
      console.log("a", a);
      await axios.post(`${process.env.VUE_APP_API_URL}/emails/send`, data);

      document.getElementById("client_name").value = "";
      document.getElementById("client_email").value = "";
      document.getElementById("subject").value = "";
      document.getElementById("type_of_sale").value = "";
      document.getElementById("property_price").value = "";
      document.getElementById("select-all").checked = "";
      document.getElementById("message-type").value = "";
      document.getElementById("text").value = "";
      document.getElementById("preview_price").value = "";

      document.getElementsByClassName("help-desk-success")[0].innerHTML =
        "Email quotation sent successfully!";
    };
    return {
      handleOnChange,
      open,
      pages,
      DateTime,
      handlePageChanged,
      current_page,
      cases: computed(() => store.state.cases.cases),
      sendEmail,
      types,
      SDLTfee,
      getPreview
    };
  }
};
</script>