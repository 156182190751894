<template>
  <div
    v-if="total > 0"
    class="
      bg-white
      px-4
      py-3
      flex
      items-center
      justify-between
      border-t border-gray-200
      sm:px-6
    "
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <button
        @click="current_page > 1 && $emit('prev-page', current_page - 1)"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Previous
      </button>
      <button
        @click="$emit('next-page', current_page + 1)"
        class="
          ml-3
          relative
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
        "
      >
        Next
      </button>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{
            (current_page - 1) * per_page + 1
          }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ current_page * per_page }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ total }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            @click="current_page > 1 && $emit('prev-page', current_page - 1)"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-l-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <template v-for="page in Math.ceil(total / per_page)" :key="page">
            <button
              v-if="page === current_page"
              aria-current="page"
              class="
                z-10
                bg-blue-50
                border-blue-500
                text-blue-600
                relative
                inline-flex
                items-center
                px-4
                py-2
                border
                text-sm
                font-medium
              "
            >
              {{ page }}
            </button>
            <button
              @click="$emit('page-changed', page)"
              v-else
              href="#"
              class="
                bg-white
                border-gray-300
                text-gray-500
                hover:bg-gray-50
                relative
                inline-flex
                items-center
                px-4
                py-2
                border
                text-sm
                font-medium
              "
            >
              {{ page }}
            </button>
          </template>
          <button
            @click="
              Math.ceil(total / per_page) !== current_page &&
                $emit('prev-page', current_page + 1)
            "
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-r-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  props: {
    total: {
      type: [String, Number],
      default: 0,
    },
    per_page: {
      type: [String, Number],
      default: 10,
    },
    current_page: {
      type: [String, Number],
      default: 1,
    },
  },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
};
</script>